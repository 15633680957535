let ListingFilters = require('./listing-filters');
let SideMenu = require('./side-menu');
let PoiMap = require('./poi-map');
let Modal = require('./modal');
let Timeline = require('./timeline');
require('./accordion').init();

let ApplyForm = require('./applyForm');

window.goldfarb = window.goldfarb || {};

$(function() {
	new ListingFilters();
	new PoiMap();
	new Modal('.js-apply-modal', '.js-apply-modal-open');
	new Timeline();
	new Modal('.js-modal', '.js-modal-open');
	new Modal('.js-nav-modal', '.js-nav-modal-open');
	new Modal('.js-contact-apply-modal', '.js-contact-apply-modal-open');

	$.getJSON("/ajax-data/quick-apply", function(data){
		window.goldfarb.regions = data;

		new ApplyForm();
	});


	let enableSidebar = window.matchMedia('(min-width: 940px)');
	if (enableSidebar) {
		new SideMenu();
	}

});
