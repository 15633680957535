'use strict';

class Accordion {
  constructor(el) {
    this.$el = $(el);
    this.init();

    $(document).on('click', '.accordion__item__head', (event) => {
      this.toggle(event);
    });
  }

  init() {
    this.$el.find('.accordion__item').each((i, item) => {
      let $content = $(item).find('.accordion__item__content');
      if ( $(item).hasClass('accordion__item--default') ) {
        $(item).addClass('accordion__item--opened');
        $content.slideDown();
      } else {
        $content.slideUp();
      }
    });
  }

  toggle(event) {
    let $target = $(event.target);
    if ( ! $target.closest('.accordion').is(this.$el) ) {
      return;
    }

    let $item = $target.closest('.accordion__item');
    this.$el.find('.accordion__item').each((i, item) => {
      if ( $(item).is($item) ) {
        $(item).toggleClass('accordion__item--opened');
        $(item).find('.accordion__item__content').slideToggle();
      } else {
        $(item).removeClass('accordion__item--opened');
        $(item).find('.accordion__item__content').slideUp();
      }
    });
  }

}

let accordions = [];
module.exports = {

  init: () => {
    $('.accordion').each((i, $accordion) => {
      accordions.push(new Accordion($accordion));
    });
  },

  /**
   * Add accordion element
   * @param  {jquery object} $accordion jquery selected .accordion element
   */
  add: ($accordion) => {
    accordions.push(new Accordion($accordion));
  },
};
