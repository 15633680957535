'use strict';

class ListingFilters {
    constructor(options = {
        debug: true
    }) {
        if (!$('.listing-group').length) {
            return;
        }
        this.filterValues = [];
        this.checkboxValues = [];
        this.b = [];
        this.b[0] = false;
        this.b[1] = false;
        this.b[2] = false;
        this.b[3] = false;
        this.unitSort = 'desc';
        if (typeof goldfarb != "undefined" && typeof goldfarb.unitSort != "undefined") {
            this.unitSort = goldfarb.unitSort;
        }
        this.init();


    }


    bindFilters() {
        $('[data-filter-type]').on('click', '[data-filter-value]', (event) => {

            if (event.type === 'click' || event.which === 13 ) {
                //prevent double firing on checkboxes
                if (event.target.tagName.toUpperCase() === "LABEL") {
                    return;
                }
                let $target = $(event.currentTarget),
                    type = $target.parents('[data-filter-type]').data('filter-type'),
                    value = $target.data('filter-value');

                //checkbox logic needs to have class
                if ($target.is(':checkbox')) {
                    if ($target.prop('checked')) {
                        this.checkboxValues['b-' + value] = "[data-" + type + "='" + value + "']";
                        this.b[value] = true;
                    } else {
                        delete this.checkboxValues['b-' + value];
                        this.b[value] = false;
                    }

                } else {
                    //prevent links from firing
                    event.preventDefault();
                    $target.siblings().removeClass('active');
                    $target.addClass('active');
                    this.addFilter(type, value);
                }
                this.filter();
            }

        });

        $('[data-filter-type] > .checkbox ').on('keypress', (event) => {
            let $target = $(event.currentTarget),
                $targetValue = $target.find('[data-filter-value]');

            if (event.which === 13) {
                $targetValue.trigger('click');

                $('.region-page .listing-group').attr('tabindex', 0).fadeIn().focus();
                $('.search-page .rentals-top-header').attr('tabindex', 0).focus();
            }
        });

        $('[data-sort-order]').on('change', (event) => {
            let $target = $(event.currentTarget);
            if ($target.val() === this.unitSort) {

                $('.listing-group').children().filter(function(){
                    return $(this).attr('data-openhouse') === '0';
                }).show();
                var partial = $('.listing-group').isotope({
                    sortAscending: true
                });
                var ma = partial.children().filter(function(){
                    return $(this).attr('data-region') === 'manhattan';
                });
                var bx = partial.children().filter(function(){
                    return $(this).attr('data-region') === 'the-bronx';
                });
                var ro = partial.children().filter(function(){
                    return $(this).attr('data-region') === 'rockaway';
                });
                var we = partial.children().filter(function(){
                    return $(this).attr('data-region') === 'westchester';
                });
                var ir = partial.children().filter(function(){
                    return $(this).attr('data-region') === 'irvington';
                });
                var it = 0;
                var ij = 0;
                var jma = $(ma);
                var jbx = $(bx);
                var jro = $(ro);
                var jwe = $(we);
                var jir = $(ir);
                if(ma.length > 0){
                    var jln = jma.length + ij + it;
                    partial[it].innerHTML = "";
                    for(var ii = 0; ii < jma.length; ii++) {
                        $(jma[ii]).attr('tabindex', 100 + ij);
                        partial[it].append(jma[ii]);
                        ij++;
                    }
                    it++;
                }
                if(bx.length > 0){
                    var jln = jbx.length + ij + it;
                    partial[it].innerHTML = "";
                    for(var ii = 0; ii < jbx.length; ii++) {
                        $(jbx[ii]).attr('tabindex', 200 + ij);
                        partial[it].append(jbx[ii]);
                        ij++;
                    }
                    it++;
                }
                if(ro.length > 0){
                    var jln = jro.length + ij + it;
                    partial[it].innerHTML = "";
                    for(var ii = 0; ii < jro.length; ii++) {
                        $(jro[ii]).attr('tabindex', 300 + ij);
                        partial[it].append(jro[ii]);
                        ij++;
                    }
                    it++;
                }
                if(we.length > 0){
                    var jln = jwe.length + ij + it;
                    partial[it].innerHTML = "";
                    for(var ii = 0; ii < jwe.length; ii++) {
                        $(jwe[ii]).attr('tabindex', 400 + ij);
                        partial[it].append(jwe[ii]);
                        ij++;
                    }
                    it++;
                }
                if(ir.length > 0){
                    var jln = jir.length + ij + it;
                    partial[it].innerHTML = "";
                    for(var ii = 0; ii < jir.length; ii++) {
                        $(jir[ii]).attr('tabindex', 500 + ij);
                        partial[it].append(jir[ii]);
                        ij++;
                    }
                    it++;
                }
                var bedrooms = this.b;
                if(!this.b[0] && !this.b[1] && !this.b[2] && !this.b[3]) {
                    bedrooms[0] = true;
                    bedrooms[1] = true;
                    bedrooms[2] = true;
                    bedrooms[3] = true;
                }
                if(bedrooms[0]) {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '0';
                    }).show();
                } else {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '0';
                    }).hide();
                }
                if(bedrooms[1]) {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '1';
                    }).show();
                } else {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '1';
                    }).hide();
                }
                if(bedrooms[2]) {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '2';
                    }).show();
                } else {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '2';
                    }).hide();
                }
                if(bedrooms[3]) {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '3';
                    }).show();
                } else {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '3';
                    }).hide();
                }
            } else if ($target.val() === 'open') {
                $('.listing-group').children().filter(function(){
                    return $(this).attr('data-openhouse') === '0';
                }).hide();
                var partial = $('.listing-group').isotope({
                    sortAscending: false
                });
                var ma = partial.children().filter(function(){
                    return $(this).attr('data-region') === 'manhattan';
                });
                var bx = partial.children().filter(function(){
                    return $(this).attr('data-region') === 'the-bronx';
                });
                var ro = partial.children().filter(function(){
                    return $(this).attr('data-region') === 'rockaway';
                });
                var we = partial.children().filter(function(){
                    return $(this).attr('data-region') === 'westchester';
                });
                var ir = partial.children().filter(function(){
                    return $(this).attr('data-region') === 'irvington';
                });
                var it = 0;
                var ij = 0;
                var jma = $(ma);
                var jbx = $(bx);
                var jro = $(ro);
                var jwe = $(we);
                var jir = $(ir);
                if(ma.length > 0){
                    var jln = jma.length + ij + it;
                    partial[it].innerHTML = "";
                    for(var ii = 0; ii < jma.length; ii++) {
                        $(jma[ii]).attr('tabindex', 100 + jln - ij);
                        partial[it].append(jma[ii]);
                        ij++;
                    }
                    it++;
                }
                if(bx.length > 0){
                    var jln = jbx.length + ij + it;
                    partial[it].innerHTML = "";
                    for(var ii = 0; ii < jbx.length; ii++) {
                        $(jbx[ii]).attr('tabindex', 200 + jln - ij);
                        partial[it].append(jbx[ii]);
                        ij++;
                    }
                    it++;
                }
                if(ro.length > 0){
                    var jln = jro.length + ij + it;
                    partial[it].innerHTML = "";
                    for(var ii = 0; ii < jro.length; ii++) {
                        $(jro[ii]).attr('tabindex', 300 + jln - ij);
                        partial[it].append(jro[ii]);
                        ij++;
                    }
                    it++;
                }
                if(we.length > 0){
                    var jln = jwe.length + ij + it;
                    partial[it].innerHTML = "";
                    for(var ii = 0; ii < jwe.length; ii++) {
                        $(jwe[ii]).attr('tabindex', 400 + jln - ij);
                        partial[it].append(jwe[ii]);
                        ij++;
                    }
                    it++;
                }
                if(ir.length > 0){
                    var jln = jir.length + ij + it;
                    partial[it].innerHTML = "";
                    for(var ii = 0; ii < jir.length; ii++) {
                        $(jir[ii]).attr('tabindex', 500 + jln - ij);
                        partial[it].append(jir[ii]);
                        ij++;
                    }
                    it++;
                }
                var bedrooms = this.b;
                if(!this.b[0] && !this.b[1] && !this.b[2] && !this.b[3]) {
                    bedrooms[0] = true;
                    bedrooms[1] = true;
                    bedrooms[2] = true;
                    bedrooms[3] = true;
                }
                if(bedrooms[0]) {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '0';
                    }).show();
                } else {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '0';
                    }).hide();
                }
                if(bedrooms[1]) {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '1';
                    }).show();
                } else {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '1';
                    }).hide();
                }
                if(bedrooms[2]) {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '2';
                    }).show();
                } else {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '2';
                    }).hide();
                }
                if(bedrooms[3]) {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '3';
                    }).show();
                } else {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '3';
                    }).hide();
                }
                $('.listing-group').children().filter(function(){
                    return $(this).attr('data-openhouse') === '0';
                }).hide();
            } else {
                $('.listing-group').children().filter(function(){
                    return $(this).attr('data-openhouse') === '0';
                }).show();
                var partial = $('.listing-group').isotope({
                    sortAscending: false
                });
                var ma = partial.children().filter(function(){
                    return $(this).attr('data-region') === 'manhattan';
                });
                var bx = partial.children().filter(function(){
                    return $(this).attr('data-region') === 'the-bronx';
                });
                var ro = partial.children().filter(function(){
                    return $(this).attr('data-region') === 'rockaway';
                });
                var we = partial.children().filter(function(){
                    return $(this).attr('data-region') === 'westchester';
                });
                var ir = partial.children().filter(function(){
                    return $(this).attr('data-region') === 'irvington';
                });
                var it = 0;
                var ij = 0;
                var jma = $(ma);
                var jbx = $(bx);
                var jro = $(ro);
                var jwe = $(we);
                var jir = $(ir);
                if(ma.length > 0){
                    var jln = jma.length + ij + it;
                    partial[it].innerHTML = "";
                    for(var ii = 0; ii < jma.length; ii++) {
                        $(jma[ii]).attr('tabindex', 100 + jln - ij);
                        partial[it].append(jma[ii]);
                        ij++;
                    }
                    it++;
                }
                if(bx.length > 0){
                    var jln = jbx.length + ij + it;
                    partial[it].innerHTML = "";
                    for(var ii = 0; ii < jbx.length; ii++) {
                        $(jbx[ii]).attr('tabindex', 200 + jln - ij);
                        partial[it].append(jbx[ii]);
                        ij++;
                    }
                    it++;
                }
                if(ro.length > 0){
                    var jln = jro.length + ij + it;
                    partial[it].innerHTML = "";
                    for(var ii = 0; ii < jro.length; ii++) {
                        $(jro[ii]).attr('tabindex', 300 + jln - ij);
                        partial[it].append(jro[ii]);
                        ij++;
                    }
                    it++;
                }
                if(we.length > 0){
                    var jln = jwe.length + ij + it;
                    partial[it].innerHTML = "";
                    for(var ii = 0; ii < jwe.length; ii++) {
                        $(jwe[ii]).attr('tabindex', 400 + jln - ij);
                        partial[it].append(jwe[ii]);
                        ij++;
                    }
                    it++;
                }
                if(ir.length > 0){
                    var jln = jir.length + ij + it;
                    partial[it].innerHTML = "";
                    for(var ii = 0; ii < jir.length; ii++) {
                        $(jir[ii]).attr('tabindex', 500 + jln - ij);
                        partial[it].append(jir[ii]);
                        ij++;
                    }
                    it++;
                }
                var bedrooms = this.b;
                if(!this.b[0] && !this.b[1] && !this.b[2] && !this.b[3]) {
                    bedrooms[0] = true;
                    bedrooms[1] = true;
                    bedrooms[2] = true;
                    bedrooms[3] = true;
                }
                if(bedrooms[0]) {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '0';
                    }).show();
                } else {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '0';
                    }).hide();
                }
                if(bedrooms[1]) {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '1';
                    }).show();
                } else {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '1';
                    }).hide();
                }
                if(bedrooms[2]) {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '2';
                    }).show();
                } else {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '2';
                    }).hide();
                }
                if(bedrooms[3]) {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '3';
                    }).show();
                } else {
                    $('.listing-group').children().filter(function(){
                        return $(this).attr('data-bedrooms') === '3';
                    }).hide();
                }
            }
        });

        $('[data-aria-sort]').on('change', (e) => {
            let sortInfo = document.querySelector('[data-sort-info]');
            let targetText = e.currentTarget.options[e.currentTarget.selectedIndex].text;
            if (sortInfo) {
                sortInfo.innerHTML = `Sorted From ${targetText}`;
            }
        })

    }

    addFilter(type, value) {
        if (value === "*") {
            this.filterValues[type] = "[data-" + type + "]";
        } else {
            this.filterValues[type] = "[data-" + type + "='" + value + "']";
        }
    }

    removeFilter(key) {
        delete this.filterValues[key];
    }

    getFilters() {
        let value = '';
        for (let prop in this.filterValues) {
            value += this.filterValues[prop];

        }
        // if checkboxes have values then add them
        if (Object.keys(this.checkboxValues).length) {
            let values = [];
            for (let prop in this.checkboxValues) {
                values.push(value + this.checkboxValues[prop]);
            }
            value = values.length ? values.join(', ') : '*';
            console.log(values);
        }
        return value;

    }

    filter() {
        let _this = this;
        this.$listings.isotope({filter: _this.getFilters()});
        this.$listings.each(function(){
            let $this = $(this),
                $group = $this.parents('.corner-group');
            if ($group.length){
                if (! $this.data('isotope').filteredItems.length) {
                    //$group.hide();
                } else {
                    //$group.show();
                }
            } else {
                if($this.data('isotope').filteredItems.length > 0){
                    $('.listing--no-results').hide();
                } else {
                    $('.listing--no-results').attr('tabindex', 0).fadeIn().focus();
                }
            }
        });
    }

    init() {
        $(window).on('load', () => {
            console.log('module init loaded');
            this.$listings = $('.listing-group');

            this.$listings.each(function(){
                $(this).isotope({
                    itemSelector: '.listing-tile',
                    layoutMode: 'fitRows',
                    sortBy: 'number',
                    sortAscending: true,
                    fitRows: {
                        gutter: 0
                    }
                });
            });

            //init filters
            this.bindFilters();
        });
    }
}

module.exports = ListingFilters;
