'use strict';

class PoiMap {
    constructor(options = {
        debug: false
    }) {
        this.mapId = 'poiMap';
        if (!$('#pois').length) {
            return;
        }

        this.icons = {
            poi: "/custom_assets/images/pin-poi.svg",
            default: "/custom_assets/images/pin-building.svg"
        };
        this.markerData = [];
        this.poiMarkers = [];
        this.markers = [];
        this.config = {
            scrollwheel: false,
            disableDefaultUI: true,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.LEFT_TOP
            },
            zoom: 14,
            maxZoom: null,
            styles: [{"featureType":"all","elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"featureType":"all","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry.fill","stylers":[{"color":"#81b97e"},{"saturation":"-10"},{"lightness":"12"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#010b20"},{"lightness":"42"},{"saturation":"-75"}]}]
          };

        this.init();
    }

    init() {
        //document ready
        $(() => {
            //get coordinates of data attribute
            this.coordinates = $('#' + this.mapId).data('coordinates');
            this.config.center = this.coordinates || {
                lat: 40.7518945,
                lng: -73.972176
            };
            this.map = new google.maps.Map(document.getElementById(this.mapId), this.config);
            if (Object.keys(this.coordinates).length) {
                this.plotMarker();
            }
            this.bindPoiButtons();

        });

    }

    /**
     * [plotMarker plots main marker on map]
     */
    plotMarker() {
        let newMarker = new google.maps.Marker({
            position: this.coordinates,
            map: this.map,
            icon: this.icons.default
        });
        //add marker to array
        this.markers.push(newMarker);

    }

    clearAllPOIMarkers() {

        for (var i = 0; i < this.poiMarkers.length; i++) {
            this.poiMarkers[i].setMap(null);
        }
        this.poiMarkers.length = 0;
    }

    plotAllPOIMarkers(results, status) {
        this.clearAllPOIMarkers();
        if (status === google.maps.places.PlacesServiceStatus.OK) {
            for (var i = results.length - 1; i >= 0; i--) {
                this.plotPOIMarker(results[i]);
            }
        }
    }


    plotPOIMarker(place) {
            let placeLoc = place.geometry.location;
            let marker = new google.maps.Marker({
                map: this.map,
                position: place.geometry.location,
                title: place.name,
                icon: this.icons.poi
            });
            this.poiMarkers.push(marker);
            let _this = this;
            google.maps.event.addListener(marker, 'click', function() {
                _this.infowindow.setContent(place.name);
                _this.infowindow.open(_this.map, this);
            });
        }
        /**
         * plots poi by term on map
         *
         * @param  array terms list of google places types
         */
    plotPOIs(term) {
        let type = term || null;

        if (this.service === undefined) {
            this.service = new google.maps.places.PlacesService(this.map);
        }

        if (this.infowindow === undefined) {
            this.infowindow = new google.maps.InfoWindow();
        }
        if (type !== null) {

            this.service.nearbySearch({
                location: this.config.center,
                radius: 1500,
                sortby: 'distance',
                type: type,
            }, this.plotAllPOIMarkers.bind(this));

        } else {

            this.clearAllPOIMarkers();

        }

    }
    bindPoiButtons() {
        $('#pois').on('click', '[data-poi]', (event) => {
            event.preventDefault();
            let $el = $(event.currentTarget),
                term = $el.data('poi');

            term = (term !== undefined) ? term : null;
            this.plotPOIs(term);
            $el.siblings().removeClass('active');
            $el.addClass('active');
        });
    }

}

module.exports = PoiMap;
