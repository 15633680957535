'use strict';

class SideMenu {
  constructor(options = {debug: false}) {
    if ( ! window.matchMedia('(min-width: 900px)') || ! $('.side-menu').length ) {
      return;
    }

    this.controller = new ScrollMagic.Controller();

    this.scene = new ScrollMagic.Scene({
      // the triggering element
      triggerElement: '.side-menu-trigger',

      // as trigger element leaves viewport
      triggerHook: 0,

      // trigger element offset to top of page
      offset: $('.side-menu-trigger').height(),

      // Pin ends when end trigger is entering the viewport
      duration: $(document).height()
      - $(window).height()
      - $('.side-menu-end-trigger').height()
    }).addTo(this.controller).setPin('.side-menu', { pushFollowers: false });

    if ( options.debug ) {
      this.scene.addIndicators();
    }

    $(window).on('resize', () => { this.resize() });
  }

  resize() {
    let enabled = window.matchMedia('(min-width: 900px)');
    if (enabled) {
      try {
        this.scene.setOffset($('.side-menu-trigger').height());
        this.scene.setDuration($(document).height()
          - $(window).height()
          - $('.side-menu-end-trigger').height());
      } catch(e) {

      }
    }

    this.scene.enabled(enabled);
  }
}

module.exports = SideMenu;
