'use strict';

class ApplyForm {
    constructor(options = {debug: false}) {
        this.$applyForm = $('#applyForm');

        // exit if form or region json is not avaliable
        if (!goldfarb.regions.length || !this.$applyForm.length) {
            return;
        }

        this.regions = goldfarb.regions;
        this.selectedRegion = false;

        this.createRegions();

        if (options.debug) {
            console.log('Regions:', goldfarb.regions);
        }

    }

    createRegions(){
        let html = '';
        this.regions.forEach((region, index)=>{
            html += '<label for="region'+ region.name +'" class="region-select" tabindex="0"><input id="region'+ region.name +'" type="radio" name="region" value="' + index + '"><span>' + region.name +'</span></label>';
        });
        this.$applyForm.find('.region-select-group').html(html);

        this.onRegionSelect();
    }

    onRegionSelect(){
        this.$applyForm.find('.region-select').on('change', (e) =>{
            this.updateBedrooms(e.target.value);
        });

        this.$applyForm.find('.region-select').on('keypress', (e) =>{
            let $target = $(e.currentTarget);

            if (e.which === 13) {
                $target.trigger('click');
            }
        });

    }

    updateBedrooms(index){
        let $bedroomGroup = this.$applyForm.find('.bedroom-apply-group');


        $bedroomGroup.animate({opacity: 0.0}, () => {
            let html = '';

            for (var unitSize in this.regions[index]['wait_unit_apply_array']) {
                html += '<label class="bedroom-select" tabindex="0"><input type="radio" name="bedroom" value="' + this.regions[index]['wait_unit_apply_array'][unitSize] + '">';
                html += '<span>' + ((unitSize == 0) ? 'studio' : unitSize) + ((unitSize == 3) ? '+' : '') + '</span></label>';

            }
            this.$applyForm.find('.bedroom-select-group').html(html);
            $bedroomGroup.find('button').hide();
            $bedroomGroup.animate({opacity: 1.0});
            $bedroomGroup.attr('tabindex', 0).focus();
            this.onBedroomSelect();
        });

    }

    onBedroomSelect(){
        this.$applyForm.find('.bedroom-select').on('change', (e) =>{
            this.$applyForm.find('.bedroom-apply-group button').fadeIn();
        });
        this.$applyForm.find('.bedroom-select').on('keypress', (e) =>{
            let $target = $(e.currentTarget);

            if (e.which === 13) {
                $target.trigger('click');
            }
        });
        this.onApplyClick();
    }

    onApplyClick(){
        this.$applyForm.find('.bedroom-select-align button').on('click keypress', (e) =>{
            let url = this.$applyForm.find('.bedroom-select-align input:checked').val();

            if ( e.type === 'click' || e.which === 13) {
                window.open(url, '_blank');
            }
        })
    }


}

module.exports = ApplyForm;
