'use strict';

class Timeline {

  constructor (options) {
    this.options = options || {};

    this.startPage = this.options.startPage || 0;
    this.pageSelector = this.options.pageSelector || '.timeline-page';
    this.pagePrefix = this.options.pagePrefix || '.timeline-page-';
    this.previousButton = this.options.previousButton || '.previous';
    this.nextButton = this.options.nextButton || '.next';

    this.currentPage = this.startPage;
    this.numOfPages = $(this.pageSelector).length;

    this.init();
  }

  pagination() {
    $(this.pagePrefix + this.currentPage).removeClass('inactive').addClass('active');
    $(this.pagePrefix + (this.currentPage - 1)).addClass('inactive').removeClass('active');
    $(this.pagePrefix + (this.currentPage + 1)).removeClass('active');
  }

  previous() {
    if (this.currentPage === this.startPage) return;

    this.currentPage--;
    this.pagination();
  }

  next() {
    if (this.currentPage === this.numOfPages-1) return;

    this.currentPage++;
    this.pagination();
  }

  init() {
    $(this.pagePrefix + this.currentPage).addClass('active');

    $(this.previousButton).on('click', () => {
      this.previous();
      return false;
    });

    $(this.nextButton).on('click', () => {
      this.next();
      return false;
    });
  }

}

module.exports = Timeline;
