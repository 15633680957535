'use strict';

class Modal {
    constructor(modal, modalTrigger, options = {
            debug: false,
            speed: 500,
            trigger: $(modalTrigger)
        }){

        this.$modal = $(modal);
        this.speed = options.speed;
        this.trigger = options.trigger;

        if(this.$modal.length > 0){
            this.init();
        }
    }

    bindClose(){
        this.$modal.find('.modal__close').on('click keydown', (event)=>{
            if (event.type === 'click' || event.which === 13 || event.keyCode === 13 ) {
                event.preventDefault();
                event.stopPropagation();
                this.close();
                $('main')
                    .off('.modalOpen')
                    .focus();
            }
        });
    }

    bindOpen(){
        $(this.trigger).on('click', (event)=>{
            event.preventDefault();
            event.stopPropagation();
            this.open();
            this.$modal.focus();
            $('main').on('focusin.modalOpen', function(e) {
                $('.apply-modal').focus();
                console.log('modal trigger');
            });
        });
    }

    close(){
        this
            .$modal
            .fadeOut(this.speed)
            .attr('aria-hidden','true');
        $('body')
            .removeClass('modal-open')
            .attr('aria-hidden','false');
    }

    open(){
        var $scroll = ( $(window).scrollTop() ) * -1;
        $('body')
            .addClass('modal-open')
            .css('top', $scroll)
            .attr('aria-hidden','true');
        this
            .$modal
            .fadeIn(this.speed)
            .attr('aria-hidden','false');
    }

    init(){
        $(() => {
            this.bindClose();
            this.bindOpen();
        });
    }


}
module.exports =  Modal;
